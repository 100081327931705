import { css } from "@emotion/react";

const globalStyles = (theme) => css`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    font-family: "Inter", sans-serif !important;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  body {
    overscroll-behavior-y: none;
    background-color: ${theme.colors.backgroundColor};
    color: ${theme.colors.text.base};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default globalStyles;
