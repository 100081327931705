import React from "react";
import Helmet from "react-helmet";
import { isSafari, isMobileSafari } from "react-device-detect";

const isDesktopSafari = isSafari && !isMobileSafari;

let GlobalHelmet = ({
  title = "Chamjo | The ultimate UX inspiration hub for local apps and competitors.",
  description = "Your go-to source for The ultimate UX inspiration hub for local apps and competitors..",
  meta = [],
  noIndex = false,
}) => {
  let metaTag = [
    // viewport
    {
      name: "viewport",
      content: !isDesktopSafari
        ? "width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, minimum-scale=1, user-scalable=no"
        : "width=1024, user-scalable=no",
    },

    // global
    {
      name: "description",
      content: description,
    },
    {
      property: "keywords",
      content: "chamjo, chamjo design",
    },

    // open graph
    {
      property: "og:url",
      content: window.location.href,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:site_name",
      content: "Chamjo Design",
    },
    {
      property: "og:image",
      content:
        "https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/Banner1.webp",
    },
    {
      property: "og:description",
      content: description,
    },

    // twitter
    {
      property: "twitter:card",
      content: "summary",
    },
    {
      property: "twitter:title",
      content: title,
    },
    {
      property: "twitter:site",
      content: "Chamjo Design",
    },
    {
      property: "twitter:image",
      content:
        "https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/Banner1.webp",
    },
    {
      property: "twitter:image:src",
      content:
        "https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/Banner1.webp",
    },
    {
      property: "twitter:description",
      content: description,
    },

    // browser theme
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    ...meta,
  ];

  //   if (noIndex) {
  //     metaTag.push({
  //       name: "robots",
  //       content: "noindex",
  //     });
  //   }

  return (
    <Helmet defer={false} title={title} meta={metaTag}>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/favicon-16x16.png"
      />
      <meta name="msapplication-TileColor" content="#E06343" />
      <meta
        name="msapplication-TileImage"
        content="https://ndbqcbbgigoygotysyae.supabase.co/storage/v1/object/public/icon/ms-icon-144x144.png"
      />
    </Helmet>
  );
};

export default GlobalHelmet;
