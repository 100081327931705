/* eslint-disable react/jsx-no-constructed-context-values */
import { Global, ThemeProvider as EmotionThemeProvider } from "@emotion/react";

import lightTheme from "./themes/light";
import fontsCss from "./font";
import globalStyles from "./global";

const ThemeProvider = ({ children }) => {
  const theme = lightTheme;

  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={fontsCss} />
      <Global styles={globalStyles} />
      {children}
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
