const themeCommon = {
  fontSize: {
    title: {
      1: 40,
      2: 32,
      3: 28,
      4: 24,
      5: 20,
      6: 16,
    },
    body: {
      1: 12,
      2: 14,
      3: 16,
    },
    subheading: {
      1: 14,
      2: 16,
    },
    heading: 24,
  },
  fontFamily: "'Inter', sans-serif",
  spacing: {
    small: 8,
    middle: 16,
    large: 32,
  },
};

export default themeCommon;
