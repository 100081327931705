import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
`;

const Content = styled.h2`
  font-size: 24px;
`;

const Button = styled.button`
  background-color: #e06343;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;

  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
  }
`;

const ErrorPage = () => {
  const history = useHistory();

  const onClick = () => {
    history.push("/");
  };
  return (
    <Wrapper>
      <Content>404 | Something when wrong</Content>
      <Button onClick={onClick}>Back to home</Button>
    </Wrapper>
  );
};

export default ErrorPage;
