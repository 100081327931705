import { css } from "@emotion/react"

const fontsCss = css`
    html,
    body {
        font-family: "Inter", sans-serif !important;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
`

export default fontsCss
